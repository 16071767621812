<template>
  <div class="pa-2">
    <v-card>
      <v-row class="pa-2 flex-column flex-sm-row" dense>
        <v-col align-self="center">
          <div class="text-center text-uppercase">next session</div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div class="justify-cente">
              <p class="text-uppercase primary--text font-weight-bold">house</p>
              <div class="d-flex">
                <v-icon color="primary">mdi-weather-partly-cloudy</v-icon>
                <p class="my-auto ml-2">morning session</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="primary">mdi-calendar-outline</v-icon>
                <p class="my-auto ml-2">thur, Jan 8 2022</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="primary">mdi-clock-outline</v-icon>
                <p class="my-auto ml-2">8.00 AM</p>
              </div>
            </div>
          </div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div>
              <p class="text-uppercase green--text font-weight-bold">
                commitee
              </p>
              <div class="d-flex">
                <v-icon color="green">mdi-weather-sunny</v-icon>
                <p class="my-auto ml-2">afternoon session</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="green">mdi-calendar-outline</v-icon>
                <p class="my-auto ml-2">thur, Jan 8 2022</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="green">mdi-clock-outline</v-icon>
                <p class="my-auto ml-2">1:00 PM</p>
              </div>
            </div>
          </div>
        </v-col>

        <v-divider vertical inset class="mr-3"></v-divider>

        <v-col>
          <div class="d-flex flex-column text-capitalize">
            <div>
              <p class="text-uppercase purple--text font-weight-bold">joint</p>
              <div class="d-flex">
                <v-icon color="purple">mdi-account-group</v-icon>
                <p class="my-auto ml-2">budget and sports commitee</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="purple">mdi-calendar-outline</v-icon>
                <p class="my-auto ml-2">thur, Jan 8 2022</p>
              </div>
              <div class="d-flex mt-2">
                <v-icon color="purple">mdi-clock-outline</v-icon>
                <p class="my-auto ml-2">8.00 AM</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <div class="mx-auto mt-7 text-h5 font-weight-light text-capitalize">
        Approved legistlation - {{ new Date().getFullYear() }}
      </div>
    </v-row>

    <v-row class="justify-center">
      <v-col
        :cols="isMobile ? '12' : '4'"
        v-for="item in overview"
        :key="item.title"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: item.component }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="primary" rounded>
                      <v-icon x-large>{{ item.icon }}</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column">
                      <div class="primary--text text-h6">{{ item.number }}</div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        {{ item.title }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col
        :cols="isMobile ? '12' : '4'"
        v-for="item in overview2"
        :key="item.title"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? '10' : '3'"
              class="rounded-lg"
              :to="{ name: item.component }"
            >
              <v-card-text>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn icon x-large color="purple">
                      <v-icon x-large>{{ item.icon }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="7" lg="6">
                    <div class="d-flex flex-column pr-sm-0 pr-10">
                      <div class="purple--text text-h6">{{ item.number }}</div>
                      <div class="text-uppercase black--text text-subtitle-1">
                        {{ item.title }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="mt-3 flex-column flex-md-row">
      <v-col>
        <v-card>
          <v-card-title class="text-capitalize text-h5">
            communication from the chair
          </v-card-title>
          <v-card-text @click="chairDialog = true">
            <pdf-viewer></pdf-viewer>
            <v-dialog v-model="chairDialog">
              <pdf-viewer></pdf-viewer>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="text-capitalize text-h5">
            communication from the clerk
          </v-card-title>
          <v-card-text @click="clerkDialog = true">
            <pdf-viewer></pdf-viewer>
            <v-dialog v-model="clerkDialog">
              <pdf-viewer></pdf-viewer>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      chairDialog: false,
      clerkDialog: false,
      overview: [
        {
          icon: "mdi-note-edit",
          title: "order papers",
          number: 418,
          component: "OrderPapers",
        },
        {
          icon: "mdi-note-check",
          title: "bills",
          number: 418,
          component: "BillsAdopted",
        },
        {
          icon: "mdi-script-text",
          title: "motions",
          number: 418,
          component: "MotionsEnacted",
        },
      ],
      overview2: [
        {
          icon: "mdi-note-text",
          title: "petitions",
          number: 418,
          component: "Petitions",
        },
        {
          icon: "mdi-gavel",
          title: "acts",
          number: 68,
          component: "ActsAdopted",
        },
        {
          icon: "mdi-note-multiple",
          title: "papers laid",
          number: 418,
          component: "PapersLaid",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
  },
  components: {
    PdfViewer,
  },
};
</script>
